
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import { listBreadcrumb } from "./index";
import { prettierPhotoUser } from "@/utils/user";

export default defineComponent({
  setup() {
    const role = api.getRole();
    const router = useRouter();
    const searchDropdown = ref(false);
    const store = useStore();
    const activeTitle = computed(() => store.state.sideMenu.activeTitle);
    const activeUrlSubMenu = computed(
      () => store.state.sideMenu.activeUrlSubMenu
    );
    const ld = api.getLoginData();
    const photoUser = prettierPhotoUser(ld.photo);
    const nameUser = ld.name;
    const emailUser = ld.email;
    const dataProfile = reactive({
      emailLupaPassword: "",
    });

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const gotoPageAkunBank = () => {
      router.push(`/${role}/akun-bank`);
    };

    const gotoPageProfile = () => {
      router.push(`/${role}/profile`);
    };

    // let count = 0;

    watch(
      computed(() => store.state.sideMenu.activeTitle),
      () => {
        // console.log('kepanggil nih, ', count++)
      }
    );

    const modalLupaPassword = () => {
      dataProfile.emailLupaPassword = "";
      gpf.showModal("#modal-lupa-password-profile");
    };

    const requestLupaPassword = async () => {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();

      const params = new URLSearchParams();
      params.append("email", dataProfile.emailLupaPassword);

      const url = api.publicForgotPassword;
      const hitApi = await api.hitApiPut(url, params, hide);
      gpf.hideModal("#modal-lupa-password-profile");
      gpf.handleSds(hitApi);
    };

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      activeTitle,
      activeUrlSubMenu,
      photoUser,
      nameUser,
      emailUser,
      role,
      gotoPageAkunBank,
      gotoPageProfile,
      dataProfile,
      modalLupaPassword,
      requestLupaPassword,
      gpf,

      // setup breadcrumb
      listBreadcrumb,
    };
  },
  methods: {
    logout() {
      api.logout();
      cash(".dropdown-box").dropdown("hide");
    },
  },
});
