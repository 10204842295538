
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  reactive,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from "./index";
import { nestedMenu } from "@/layouts/side-menu";
import DarkModeSwitcherMenu from "@/components/dark-mode-switcher-menu/Main.vue";
import { api } from "@/utils/api-config";
import { VueCookieNext as $ck } from "vue-cookie-next";

export default defineComponent({
  components: {
    DarkModeSwitcherMenu,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const role: string = api.getRole();

    const menuList = reactive({
      listMenu: $ck.getCookie("list-menu"),
    });

    const getListMenu = (): any => {
      const menu = JSON.parse(menuList.listMenu);
      return menu;
    };

    // const listMenu = getListMenu();

    const mobileMenu = computed(() => nestedMenu(getListMenu(), route, role));

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      role,
    };
  },
});
