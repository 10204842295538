import { RouteLocationNormalizedLoaded as Route, Router } from "vue-router";
import Velocity from "velocity-animate";
import { useStore } from "@/store";

// Setup side menu
type Menu = {
  icon: string;
  pageName: string;
  title: string;
  active?: boolean;
  activeDropdown?: boolean;
  ignore?: boolean;
  subMenu?: Menu[];
};

const store = useStore();

const findActiveMenu = (subMenu: Array<Menu>, route: Route, role: string): boolean => {
  let match = false;
  subMenu.forEach((item) => {
    // const pathname = "side-menu-" + route.path.slice(1, route.path.length);
    // console.log('route pathname', pathname, route.name);
    if (role + '-' + item.pageName === route.name && !item.ignore) {
      match = true;
      // console.log('route ', match, item.pageName, pathname, route.name);
      store.dispatch('sideMenu/SET_TITLE_PAGE', item.title);
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route, role);
    }
  });
  return match;
};

const nestedMenu = (
  menu: Array<Menu | string>,
  route: Route,
  role: string,
): Array<Menu | string> => {
  const tempMenu = menu === null ? [] : menu;
  tempMenu.forEach((item, key) => {
    if (typeof item !== "string") {
      let menuItem = menu[key] as typeof item;
      menuItem.active = (role + '-' + item.pageName === route.name || (item.subMenu && findActiveMenu(item.subMenu, route, role)) ) && !item.ignore;

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route, role);
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route, role),
        };
      }

      const pathname = route.path.slice(1, route.path.length).split('/')[0];
      const sidemenu = pathname;
      // console.log('route ', item.pageName, pathname, route.path);
      if ( role + '-' + item.pageName === sidemenu && !item.ignore ) {
        store.dispatch('sideMenu/SET_TITLE_PAGE', item.title);
        store.dispatch('sideMenu/SET_URL_SUB_MENU', pathname);
      }
    }
  });

  return menu;
};

const linkTo = (menu: Menu, router: Router, role: string): void => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
  } else {
    router.push({
      name: role + '-' + menu.pageName,
    });
  }
};

const enter = (el: HTMLElement, done: () => void): void => {
  Velocity(el, "slideDown", { duration: 300 }, { complete: done });
};

const leave = (el: HTMLElement, done: () => void): void => {
  Velocity(el, "slideUp", { duration: 300 }, { complete: done });
};

export { Menu, nestedMenu, linkTo, enter, leave };
